import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 40;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>そろそろポッドキャストに改善を加えましょうか</li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=cSwOlH_qYig"
            target="_blank"
            rel="noopener noreferrer"
          >
            いい自己紹介の要素は3WH
          </a>
        </li>
        <li>サムネイルに音に反応させた波形アニメーションを入れたい</li>
        <li>なんでこのポッドキャストをやってるのかは明確じゃない</li>
        <li>一言で自分を紹介するのは大事だけど難しい</li>
        <li>竹馬の友がすべてを説明する</li>
        <li>箕澤屋ポッドキャストの意味</li>
        <li>簡単に言うと、長野県の古民家の雑談ラジオ</li>
        <li>井戸端会議ポットキャストは長くて言いづらい</li>
        <li>築160年じゃなくて、江戸でござるか</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
